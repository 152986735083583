import React, { useContext, useEffect, useState } from 'react';
import Map from "../../../Map/Map";
import WaterSideInfo from "../WaterSideInfo";
import { getDistrictsInfo } from '../../../../services/general';
import { AppDataContext } from '../../../../contexts/AppDataContext';
import { getDistrictData } from '../../../../utils';
import { IDistrictInfo } from '../../../../types/general';

const WaterSupplyRegionPage = () => {
    const { updateDistrictsData } = useContext(AppDataContext);
    const [waterSupplyDistrcits, setWaterSUpplyDistricts] = useState<IDistrictInfo[]>([]);

    useEffect(() => {
        loadDistricts();
    }, []);

    const loadDistricts = async () => {
        const response = await getDistrictsInfo();

        if (!response.isError) {
            setWaterSUpplyDistricts(response.data);
            updateDistrictsData(getDistrictData(response.data))
        }
    }

    return (
        <div>
            <Map waterSupply={waterSupplyDistrcits}/>
            <WaterSideInfo />
        </div>
    );
};

export default WaterSupplyRegionPage;