import React, {createContext, ReactNode, useState} from 'react';
import {IDistrict, IDistrictData, IHydrometeorologicalReport} from "../types/district";
import {DateRange} from "react-day-picker";
import {useLocation} from "react-router-dom";

interface IAppDataProvider {
    children: ReactNode;
}

interface IDefaultValues {
    date: Date;
    dateRange: DateRange;
    districts: IDistrict[];
    district: IDistrict | null;
    districtsData: IDistrictData[] | null;
    updateDate: (newDate: Date) => void;
    updateDateRange: (newDate: DateRange) => void;
    updateDistricts: (districts: IDistrict[]) => void;
    updateDistrict: (district: IDistrict | null) => void;
    updateDistrictsData: (district: IDistrictData[] | null) => void;
    selectedYear: {value: string, label: string};
    updateSelectedYear: (newYear: {value: string, label: string}) => void;
    selectedPoint: IHydrometeorologicalReport | null;
    setSelectedPoint: (point: IHydrometeorologicalReport | null) => void;
}

const defaultValues: IDefaultValues = {
    date: new Date(),
    dateRange: {from: new Date()},
    districts: [],
    district: null,
    districtsData: null,
    updateDate: () => {},
    updateDateRange: () => {},
    updateDistricts: () => {},
    updateDistrict: () => {},
    updateDistrictsData: () => {},
    selectedYear: {label: String(new Date().getFullYear()), value: String(new Date().getFullYear())},
    updateSelectedYear: () => {},
    selectedPoint: null,
    setSelectedPoint: () => {}
};

export const AppDataContext = createContext(defaultValues);

export const AppDataProvider: React.FC<IAppDataProvider> = ({children}) => {
    const location = useLocation();
    const dateCheck = () => {
        if (location.pathname.includes("/tenders")) {
            const yesterday = new Date();
            yesterday.setDate(new Date().getDate() - 7);
            return  yesterday;
        } else if (location.pathname.includes("/debts")) {
            const yesterday = new Date();
            yesterday.setDate(new Date().getDate() - 30);
            return  yesterday;
        } else if (location.pathname === "/power-generation") {
            const yesterday = new Date();
            yesterday.setDate(new Date().getDate() - 7);
            return  yesterday;
        } else {
            return new Date();
        }
    };

    const [date, setDate] = useState<Date>(() => {
        const savedDate = localStorage.getItem('date');
        return savedDate ? new Date(savedDate) : dateCheck();
    });
    const [dateRange, setDateRange] = useState<DateRange>(() => {
        const from = localStorage.getItem("dateFrom");
        const to = localStorage.getItem("dateTo");
        const yesterday = new Date();
        yesterday.setDate(new Date().getDate() - 7);
        return (from && to) ? {from: new Date(from), to: new Date(to)} : {from: dateCheck(), to: new Date()};
    });
    const [districts, setDistricts] = useState<IDistrict[] | []>([]);
    const [district, setDistrict] = useState<IDistrict | null>(null);
    const [districtsData, setDistrictsData] = useState<IDistrictData[] | null>(null);
    const [selectedYear, setSelectedYear] = useState<{value: string, label: string}>(
        {label: String(new Date().getFullYear()), value: String(new Date().getFullYear())}
    );
    const [selectedPoint, setSelectedPoint] = useState<IHydrometeorologicalReport | null>(null); 

    const updateDate = (newDate: Date) => {
        setDate(newDate);
    };

    const updateDateRange = (newDate: DateRange) => {
        setDateRange(newDate);
    };

    const updateSelectedYear = (newYear: {value: string, label: string}) => {
        setSelectedYear(newYear);
    };

    const updateDistricts = (districts: IDistrict[]) => {
        setDistricts(districts);
    };

    const updateDistrict = (district: IDistrict | null) => {
        setDistrict(district);
    };

    const updateDistrictsData = (district: IDistrictData[] | null) => {
        setDistrictsData(district);
    };

    return (
        <AppDataContext.Provider value={{ date, dateRange, districts, district, districtsData, updateDate, updateDistricts, updateDistrict, updateDateRange, updateDistrictsData, selectedYear, updateSelectedYear, selectedPoint, setSelectedPoint }}>
            {children}
        </AppDataContext.Provider>
    );
}
