import { useParams } from "react-router-dom";
import Map from "../../../Map/Map";
import WaterSideInfo from "../WaterSideInfo";
import { useContext, useEffect, useState } from "react";
import { getDistrictsInfo } from "../../../../services/general";
import { getDistrictData } from "../../../../utils";
import { AppDataContext } from "../../../../contexts/AppDataContext";
import { IDistrictInfo } from "../../../../types/general";


const WaterSupplyDistrictPage = () => {
    const { updateDistrictsData } = useContext(AppDataContext);
    const [waterSupplyDistrcits, setWaterSUpplyDistricts] = useState<IDistrictInfo[]>([]);
    const params = useParams();
    const districtId = params.id;
    
    useEffect(() => {
        loadDistricts();
    }, []);

    const loadDistricts = async () => {
        const response = await getDistrictsInfo();

        if (!response.isError) {
            setWaterSUpplyDistricts(response.data);
            updateDistrictsData(getDistrictData(response.data))
        }
    }

    return (
        <>
            <Map districtId={Number(districtId)} waterSupply={waterSupplyDistrcits} regionOnly/>
            <WaterSideInfo />
        </>
    )
}

export default WaterSupplyDistrictPage;