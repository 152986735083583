import {ustKamenogorsk} from "../coordinates/vkoDistricts/ust_kamenogorsk";
import {ridderDistrict} from "../coordinates/vkoDistricts/ridder_district";
import {altaiDistrict} from "../coordinates/vkoDistricts/altai_district";
import {markakolDistrict} from "../coordinates/vkoDistricts/markakol_district";
import {ulkenNarinDistrict} from "../coordinates/vkoDistricts/ulken_narın_district";
import {glubokoeDistrict} from "../coordinates/vkoDistricts/glubokoe_district";
import {zaysanDistrict} from "../coordinates/vkoDistricts/zaysan_district";
import {katonkaragayDistrict} from "../coordinates/vkoDistricts/katonkaragay_district";
import {kourchimDistrict} from "../coordinates/vkoDistricts/kourchim_district";
import {ulanDistrict} from "../coordinates/vkoDistricts/ulan_district";
import {shemonaikhaDistrict} from "../coordinates/vkoDistricts/shemonaikha_district";
import {samarDistrict} from "../coordinates/vkoDistricts/samar_district";
import {tarbagatayDistrict} from "../coordinates/vkoDistricts/tarbagatay_district";
import {IDistrict} from "../types/district";

export const isMobileDevice = () => {
    return window.innerWidth < 1200;
};

export const getDistrictCoordinates = (id: number) => {
    switch (id) {
        case 29:
            return ustKamenogorsk;
        case 30:
            return ridderDistrict;
        case 31:
            return altaiDistrict;
        case 32:
            return markakolDistrict;
        case 33:
            return ulkenNarinDistrict;
        case 34:
            return glubokoeDistrict;
        case 35:
            return zaysanDistrict;
        case 36:
            return katonkaragayDistrict;
        case 37:
            return kourchimDistrict;
        case 38:
            return ulanDistrict;
        case 39:
            return shemonaikhaDistrict;
        case 40:
            return samarDistrict;
        case 41:
            return tarbagatayDistrict;
        default:
            console.log('wrong id');
    }
};

export const getCheckpointStatus = (status: string) => {
    if (status === "medium") {
        return "Стабильная";
    } else if (status === "high") {
        return "Критичная";
    } else {
        return "Нормальная";
    }
};

export const getMonthName = (month: number) => {
    switch (month) {
        case 1:
            return "Янв";
        case 2:
            return "Фев";
        case 3:
            return "Март";
        case 4:
            return "Апр";
        case 5:
            return "Май";
        case 6:
            return "Июнь";
        case 7:
            return "Июль";
        case 8:
            return "Авг";
        case 9:
            return "Сен";
        case 10:
            return "Окт";
        case 11:
            return "Ноя";
        case 12:
            return "Дек";
        default:
            console.log('wrong month');
    }
};

export const getDistrictCoordinatesWithNames = () => {
    return [
        {name: "Усть-Каменогорск Г.А.", coords: ustKamenogorsk},
        {name: "Риддер Г.А.", coords: ridderDistrict},
        {name: "район Алтай", coords: altaiDistrict},
        {name: "Глубоковский район", coords: glubokoeDistrict},
        {name: "Зайсанский район", coords: zaysanDistrict},
        {name: "Катон-Карагайский район", coords: katonkaragayDistrict},
        {name: "Курчумский район", coords: kourchimDistrict},
        {name: "Уланский район", coords: ulanDistrict},
        {name: "Шемонаихинский район", coords: shemonaikhaDistrict},
        {name: "район Самар", coords: samarDistrict},
        {name: "Тарбагатайский район", coords: tarbagatayDistrict},
        {name: "район Марқакөл", coords: markakolDistrict},
        {name: "район Үлкен Нарын", coords: ulkenNarinDistrict},

    ]
};

export const swapCoordinates = (coords: any) => {
    return coords.map((subArray: any[]) => [subArray[1], subArray[0]]);
};

export const getDistrictStatus = (district: IDistrict) => {
    if (district.hydrometeorological_reports.length) {
        let critical = 0;
        let medium = 0;
        district.hydrometeorological_reports.forEach((point) => {
            if (point.water_level_status.toLowerCase() === "high") critical++;
            if (point.water_level_status.toLowerCase() === "medium") medium++;
        });
        if (critical > 0) {
            return "marker table-marker-big high";
        } else if (medium > 0) {
            return "marker table-marker-big medium";
        } else {
            return "marker table-marker-big";
        }
    } else {
        return "marker table-marker-big";
    }
};

export const getDistrictNameById = (id: number) => {
    switch (id) {
        case 29:
            return "Усть-Каменогорск Г.А.";
        case 30:
            return "Риддер Г.А.";
        case 31:
            return "район Алтай";
        case 32:
            return "район Марқакөл";
        case 33:
            return "район Үлкен Нарын";
        case 34:
            return "Глубоковский район";
        case 35:
            return "Зайсанский район";
        case 36:
            return "Катон-Карагайский район";
        case 37:
            return "Курчумский район";
        case 38:
            return "Уланский район";
        case 39:
            return "Шемонаихинский район";
        case 40:
            return "район Самар";
        case 41:
            return "Тарбагатайский район";
        default:
            console.log('wrong id');
    }
};

export const getDistrictCenter = (id: number) => {
    switch (id) {
        case 29:
            return [49.9499, 82.6165];
        case 30:
            return [50.3391, 83.5059];
        case 31:
            return [49.7307, 84.261];
        case 32:
            return [48.4164, 85.7332];
        case 33:
            return [49.211111, 84.513611];
        case 34:
            return [50.1457, 82.3068];
        case 35:
            return [47.4648, 84.8756];
        case 36:
            return [49.2109, 84.5137];
        case 37:
            return [48.5671, 83.665];
        case 38:
            return [49.8765, 82.4847];
        case 39:
            return [50.6294, 81.9044];
        case 40:
            return [49.1664, 83.5002];
        case 41:
            return [47.7615, 82.8067];
        default:
            console.log('wrong id');
    }
};

export const getSumString = (num: number | null) => {
    if(num === null) {
        return '0';
    }
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + ' млрд';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + ' млн';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + ' тыс';
    }
    return num.toString();
};

export const getOptionNameById = (options: {id: number, label: string}[], id: number) => {
    const option = options.find(opt => opt.id === id);
    return option ? option.label : "-";
};


export const getDistrictData = (districts: any) => {
    return districts.map((district: any) => {
        return {id: district.id, name: district.name}
    });
};

export const addSpaces = (num: string): string => {
    const parts: string[] = num.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export const getDistrictKatoById = (id: number) => {
    switch (id) {
        case 29:
            return "631000000";
        case 30:
            return "632400000";
        case 31:
            return "634800000";
        case 32:
            return "635500000";
        case 33:
            return "636300000";
        case 34:
            return "634000000";
        case 35:
            return "634600000";
        case 36:
            return "635400000";
        case 37:
            return "635200000";
        case 38:
            return "636200000";
        case 39:
            return "636800000";
        case 40:
            return "635600000";
        case 41:
            return "635800000";
        default:
            console.log('wrong id');
    }
}