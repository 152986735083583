import axios from "axios";
import axiosInstance from "./axiosInstance";
import { TResult } from "./types";
import { ICountyInfo, IDistrictInfo } from "../types/general";

export const getDistrictsInfo = async (): Promise<TResult<IDistrictInfo[]>> => {
    try {
        const response = await axiosInstance.get('/api/cases/info/districts/', {
            headers: {
                'Authorization': 'Token 445a772820fb2eca45521ae1881e6a7a882b0e39',
            }
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getCountiesInfo = async (districtId: number): Promise<TResult<ICountyInfo[]>> => {
    try {
        const response = await axiosInstance.get('/api/cases/info/counties/', {
            params: {
                district: districtId
            },
            headers: {
                'Authorization': 'Token 445a772820fb2eca45521ae1881e6a7a882b0e39',
            }
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};