import React, {useContext, useEffect, useRef, useState} from 'react';
import {DateRange, DayPicker} from "react-day-picker";
import 'react-day-picker/dist/style.css';
import {AppDataContext} from "../../contexts/AppDataContext";
import {ChevronOutline} from "../Icons";
import dayjs from "dayjs";

interface IDatePicker {
    range?: boolean;
}

const DatePicker: React.FC<IDatePicker> = ({range}) => {
    const {date, updateDate, dateRange, updateDateRange} = useContext(AppDataContext);
    const [selectedDay, setSelectedDay] = useState<Date>(date);
    const [selectedDaysRange, setSelectedDaysRange] = useState<DateRange>();
    const [isShow, setIsShow] = useState<boolean>(false);
    const [currentMonth, setCurrentMonth] = useState<Date>(new Date()); // чтобы отслеживать послений открытый месяц в календаре
    const today = new Date();
    const datePickerRef = useRef<any>(null);

    useEffect(() => {
        setSelectedDay(date);
        setSelectedDaysRange(dateRange);
    }, [date, dateRange]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const onSelectDate = (date: Date) => {
        setSelectedDay(date);
        updateDate(date);
        setIsShow(false);
        localStorage.setItem("date", String(date));
    };

    const onSelectDateRange = (date: DateRange | undefined) => {
        setSelectedDaysRange(date);

        if (date?.to && date.from) {
            updateDateRange(date);
            localStorage.setItem("dateFrom", String(date.from));
            localStorage.setItem("dateTo", String(date.to));
        }
    };

    const changeDay = (type: "prev" | "next") => {
        let newDate;
        if (type === "prev") {
            newDate = new Date(date.setDate(date.getDate() - 1))
        } else {
            newDate = new Date(date.setDate(date.getDate() + 1))
        }
        setSelectedDay(newDate);
        updateDate(newDate);
        localStorage.setItem("date", String(newDate));
    };

    const handleClickOutside = (event: any) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setIsShow(false);
        }
    };

    return (
        <div ref={datePickerRef}>
            <div className="header-calendar">
                {!range && <div className="calendar-item prev" onClick={() => changeDay("prev")}><ChevronOutline /></div>}
                <div onClick={() => setIsShow(!isShow)}>
                    {(range && selectedDaysRange) ?
                        <>{dayjs(selectedDaysRange.from).format("DD.MM.YYYY") + " - " + dayjs(selectedDaysRange.to).format("DD.MM.YYYY")}</>
                    :
                        dayjs(date).format("DD.MM.YYYY")
                    }
                </div>
                {!range && <div className="calendar-item next" onClick={() => changeDay("next")}><ChevronOutline /></div>}
            </div>

            {isShow &&
                <div className="date-picker">
                    {range ?
                        <DayPicker
                            mode="range"
                            selected={selectedDaysRange}
                            onSelect={(date) => onSelectDateRange(date)}
                            month={currentMonth}
                            onMonthChange={setCurrentMonth}
                            modifiers={{
                                disabled: { after: today }
                            }}
                        />
                    :
                        <DayPicker
                            mode="single"
                            selected={selectedDay}
                            onDayClick={(date) => onSelectDate(date)}
                            month={currentMonth}
                            onMonthChange={setCurrentMonth}
                            modifiers={{
                                disabled: { after: today }
                            }}
                        />
                    }
                </div>
            }
        </div>
    );
};

export default DatePicker;