import React from "react";

export type AsideMenuItemProps = {
    name: string;
    href: string;
    disabled?: boolean;
};

const AsideMenuItem: React.FC<AsideMenuItemProps> = ({
    name,
    href,
    disabled = false,
}) => {
    return (
        <a
            className="header-aside-menu-link"
            href={href}
            aria-disabled={disabled}
            onClick={(event) => disabled && event.preventDefault()}
        >
            {name}
        </a>
    );
};

export default AsideMenuItem;
