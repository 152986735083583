import React from 'react';
import {Bar} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { addSpaces } from './../../utils/index';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

interface IBarChart {
    data: any;
    chartWidth?: number;
    chartHeight?: number;
    responsive: boolean
    isLegend: boolean;
    onlyGraph?: boolean;
    breakWords?: boolean;
    onClick?: (label: string, value: string) => void;
    textRotation?: number;
    withTicksOnX?: boolean;
    datalabels?: boolean; //Отображать value на самом столбце
    datalabelsText?: string; //Добавить текстовую подпись к value
    datalabelsRotation?: number; //Поворот текста
    datalabelsColor?: string; //Цвет текста
    datalabelsFormatter?: (value: any) => string | string[]; //Колбэк для форматирование лейблов
    textColor?: string;
    horizontal?: boolean;
    YTickCallback?: (value: any, index: number) => void;
}

const splitIntoPairs = (text: string) => {
    const words = text.split(' ');
    const pairs = [];

    for (let i = 0; i < words.length; i += 2) {
        let pair = words[i];
        if (i + 1 < words.length) {
            pair += ' ' + words[i + 1];
        }
        pairs.push(pair);
    }

    return pairs;
}

const BarChart: React.FC<IBarChart> = ({
                                           data,
                                           chartHeight,
                                           chartWidth,
                                           isLegend,
                                           responsive,
                                           onlyGraph,
                                           breakWords,
                                           onClick,
                                           textRotation,
                                           withTicksOnX,
                                           datalabels,
                                           datalabelsText,
                                           datalabelsRotation,
                                           datalabelsColor,
                                           datalabelsFormatter,
                                           textColor,
                                           horizontal,
                                           YTickCallback
}) => {
    const options: any = {
        indexAxis: horizontal ? 'y' : 'x',
        onClick: (event: any, elements: any) => {
            if (elements.length > 0) {
                const firstElement = elements[0];
                const datasetIndex = firstElement.datasetIndex;
                const dataIndex = firstElement.index;

                const label = data.labels[dataIndex];
                const value = data.datasets[datasetIndex].data[dataIndex];
                if (onClick) onClick(label, value);
            }
        },
        responsive: responsive,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: isLegend,
                position: "bottom",
                align: "start",
                padding: 100,
                labels: {
                    boxWidth: 25,
                    boxHeight: 5,
                }
            },
            tooltip: breakWords && {
                callbacks: {
                    title: (context: any) => {
                        return splitIntoPairs(context[0].label);
                    }
                },
            },
            datalabels: {
                display: !!datalabels,
                color: datalabelsColor ? datalabelsColor : "black",
                rotation: datalabelsRotation,
                formatter: datalabelsFormatter || ((value: string) => `${addSpaces(value.toString())} ${datalabelsText ? datalabelsText : ""}`),
                font: {
                    size: "10px",
                },
                align: (context: any) => {
                    const value = context.dataset.data[context.dataIndex];
                    return value > context.chart.scales.x.width / 10 ? 'center' : 'right';
                },
                clip: false,
            }
        },
        scales: {
            x: {
                type: horizontal ? 'logarithmic' : undefined,
                stacked: false,
                border: {
                    display: !onlyGraph,
                },
                grid: {
                    display: !onlyGraph,
                },
                ticks: {
                    display: withTicksOnX ? true : !onlyGraph,
                    maxRotation: textRotation && textRotation,
                    minRotation: textRotation && textRotation,
                    callback: function(value: any) {
                        // @ts-ignore
                        let label: any = this.getLabelForValue(value);
                        if (label.length > 20) {
                            return label.slice(0, 20) + '...';
                        }
                        return label;
                    },
                    color: textColor,
                }
            },
            y: {
                type: horizontal ? 'category' : 'logarithmic',
                stacked: false,
                border: {
                    display: !onlyGraph,
                },
                grid: {
                    display: !onlyGraph,
                },
                ticks: {
                    display: !onlyGraph,
                    callback: !YTickCallback ? function(value: any) {
                        // @ts-ignore
                        let label: string = this.getLabelForValue(value);
                        if (label.length > 20) {
                            return label.split(" ");
                        }
                        return label;
                    } : YTickCallback,
                },
            },
        },
    };

    return (
        <Bar
            data={data}
            options={options}
            width={chartWidth}
            height={chartHeight}
        />
    );
};

export default BarChart;