import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import RegionPage from "./components/FloodsPages/RegionPage/RegionPage";
import DistrictPage from "./components/FloodsPages/DistrictPage/DistrictPage";
import AuthorizationPage from "./components/AuthorizationPage/AuthorizationPage";
import FiresRegionPage from "./components/FiresPages/FiresRegionPage";
import FiresDistrictPage from "./components/FiresPages/FiresDistrictPage";
import PowerGenerationPage from "./components/HCSPages/PowerGenerationPage/PowerGenerationPage";
import DebtsRegionPage from "./components/HCSPages/DebtsPage/DebtsRegionPage";
import TendersRegionPage from "./components/TendersPages/TendersRegionPage";
import DebtsDistrictPage from "./components/HCSPages/DebtsPage/DebtsDistrictPage";
import TendersDistrictPage from "./components/TendersPages/TendersDistrictPage";
import WaterSupplyRegionPage from "./components/HCSPages/WaterPages/WaterSupplyPages/WaterSupplyRegionPage";
import InternetRegionPage from "./components/InternetPages/InternetRegionPage";
import InternetDistrictPage from "./components/InternetPages/InternetDistrictPage";
import WaterSupplyDistrictPage from "./components/HCSPages/WaterPages/WaterSupplyPages/WaterSupplyDistrictPage";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {index: true, element: <RegionPage />},
            {
                path: "/district/:id",
                element: <DistrictPage />
            },
            {
                path: "/authorization",
                element: <AuthorizationPage />
            },
            {
                path: "/fires",
                element: <FiresRegionPage />
            },
            {
                path: "/fires/district/:id",
                element: <FiresDistrictPage />
            },
            {
                path: "/power-generation",
                element: <PowerGenerationPage />
            },
            {
                path: "/debts",
                element: <DebtsRegionPage />
            },
            {
                path: "/debts/district/:id",
                element: <DebtsDistrictPage />
            },
            {
                path: "/tenders",
                element: <TendersRegionPage />
            },
            {
                path: "/tenders/district/:id",
                element: <TendersDistrictPage />
            },
            {
                path: "/water-supply",
                element: <WaterSupplyRegionPage />
            },
            {
                path: "/water-supply/district/:id",
                element: <WaterSupplyDistrictPage />
            },
            {
                path: "/internet",
                element: <InternetRegionPage />
            },
            {
                path: "/internet/district/:id",
                element: <InternetDistrictPage />
            },
        ]
    }
]);