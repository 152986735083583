import React from 'react';

const ProgressBar: React.FC<{value1: number, value2: number}> = ({value1, value2}) => {
    const percentage = (value1 / value2) * 100;
    return (
        <div>
            <div className="progress-bar">
                <div className="progress-bar-left" style ={{width: `${percentage}%`}}></div>
                <div className="progress-bar-right" style ={{width: `${100 - percentage}%`}}></div>
                <div className="progress-bar-label">{Math.round(percentage) + "%"}</div>
            </div>
        </div>
    );
};

export default ProgressBar;