import React, {useContext, useEffect, useRef, useState} from 'react';
import {ChevronOutline} from "../../Icons";
import { AppDataContext } from '../../../contexts/AppDataContext';
import { IWaterSupplyInfo, IWaterSupplyNetwork, IWaterSupplyTariffLevel } from '../../../types/water-supply';
import { getWaterSupplyInfo, getWaterSupplyNetworks, getWaterSupplyTariffLevels } from '../../../services/hcs';
import { useParams } from 'react-router-dom';
import { getDistrictKatoById } from '../../../utils';

const WaterSliderCard = () => {
    const { selectedYear } = useContext(AppDataContext);
    const [page, setPage] = useState<number>(0);
    const [info, setInfo] = useState<IWaterSupplyInfo>();
    const [level, setLevel] = useState<IWaterSupplyTariffLevel>();
    const [network, setNetwork] = useState<IWaterSupplyNetwork>();
    const titles = ["ВОДОСНАБЖЕНИЕ", "ПРОТЯЖЕННОСТЬ", "УРОВЕНЬ ТАРИФОВ"];
    const intervalRef = useRef<number | null>(null);
    const { id } = useParams(); 

    useEffect(() => {
        startInterval();

        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        }
    }, []);

    useEffect(() => {
        loadInfo();
        loadNetworks();
        loadLevels();
    }, [id, selectedYear]);

    const handleChangePage = (next?: boolean) => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }

        next ?
            setPage((prevIndex) => (prevIndex + 1) % titles.length)
        :
            setPage((prevIndex) => (prevIndex - 1 + titles.length) % titles.length)

        startInterval();
    };

    const startInterval = () => {
        intervalRef.current = window.setInterval(() => {
            setPage((prevIndex) => (prevIndex + 1) % titles.length);
        }, 30000);
    };

    const loadInfo = async () => {
        const response = await getWaterSupplyInfo(id ? getDistrictKatoById(Number(id)) as string : "", selectedYear.value);

        if (!response.isError) {
            setInfo(response.data);
        }
    };

    const loadNetworks = async () => {
        const response = await getWaterSupplyNetworks(id ? getDistrictKatoById(Number(id)) as string : "", selectedYear.value);

        if (!response.isError) {
            setNetwork(response.data);
        }
    };

    const loadLevels = async () => {
        const response = await getWaterSupplyTariffLevels(id ? getDistrictKatoById(Number(id)) as string : "", selectedYear.value);

        if (!response.isError) {
            setLevel(response.data);
        }
    };

    return (
        <div className="side-info-big-card">
            <div className="side-info-big-card-slider">
                <span className="side-info-big-card-slider-arrow prev" onClick={() => handleChangePage()}><ChevronOutline /></span>
                <span className="side-info-big-card-title">{titles[page]}</span>
                <span className="side-info-big-card-slider-arrow" onClick={() => handleChangePage(true)}><ChevronOutline /></span>
            </div>

            {page === 0 &&
                <div className="side-info-big-card-items" style={{gap: "8px"}}>
                    <div className="side-info-big-card-item">
                        <div>Бюджетных организаций (единиц)</div>
                        <div>{info?.budgetOrganizationSubscribers}</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Количество населения имеющих доступ к  централизованному водоснабжению (чел.)</div>
                        <div>{info?.subscribersWithCentralizedWaterSupply}</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>в том числе с дистанционной передачей данных в АСУЭ обслуживающего предприятия (единиц)</div>
                        <div>{info?.individualWaterMetersWithRemoteAccess}</div>
                    </div>
                    <ul>
                        <li>
                            <div className="data-list-item">
                                <div>охват в %, гр.15/гр. 9*100</div>
                                <div>{info?.individualMeterCoverage}%</div>
                            </div>
                        </li>
                    </ul>
                    <div className="side-info-big-card-item">
                        <div>Охват общедомовыми приборами учета воды по состоянию на конец отчетного года</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Количество зданий и сооружений, подлежащих к установке общедомовых приборов учета (единиц)</div>
                        <div>{info?.buildingsRequiringCommonMeters}</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Количество зданий и сооружений с установленными общедомовыми приборами учета (единиц)</div>
                        <div>{info?.buildingsWithCommonMeters}</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Количество установленных общедомовых приборов учета (единиц)</div>
                        <div>{info?.installedCommonMeters}</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>в том числе с дистанционной передачей данных в АСУЭ обслуживающего предприятия (единиц)</div>
                        <div>{info?.commonMetersWithRemoteAccess}</div>
                    </div>
                    <ul>
                        <li>
                            <div className="data-list-item">
                                <div>охват в %, гр.19/гр. 18*100</div>
                                <div>{info?.commonMeterCoverage}%</div>
                            </div>
                        </li>
                    </ul>
                </div>
            }

            {page === 1 &&
                <div className="side-info-big-card-items" style={{gap: "8px"}}>
                    <div className="side-info-big-card-item">
                        <div>Протяженность водопроводных сетей, км (по состоянию на конец отчетного года)</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Общая, км</div>
                        <div>{network?.totalLength}</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>в том числе изношенных, км</div>
                        <div>{network?.wornOutLength}</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Износ, % гр.59/гр.58</div>
                        <div>{network?.wearPercentage}</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Общая протяженность построенных (новых) сетей в отчетном году, км</div>
                        <div>{network?.newWaterSupplyLength}</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Общая протяженность реконструированных (замененных) сетей в отчетном году, км</div>
                        <div>{network?.reconstructedWaterSupplyLength}</div>
                    </div>
                </div>
            }

            {page === 2 &&
                <div className="side-info-big-card-items" style={{gap: "8px"}}>
                    <div className="side-info-big-card-item">
                        <div>Усредненный, тенге/м3</div>
                        <div>{level?.averagePricePerCubicMeter}</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Физическим лицам/населению, тенге/м3</div>
                        <div>{level?.priceForIndividuals}</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Юридическим лицам (в том числе бюджетные организации), тенге/м3</div>
                        <div>{level?.priceForLegalEntities}</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Бюджетным организациям, тенге/м3</div>
                        <div>{level?.priceForBudgetOrganizations}</div>
                    </div>
                </div>
            }
        </div>
    );
};

export default WaterSliderCard;