import React, { useContext, useEffect, useState } from 'react';
import { IWaterSupplyStatistic } from '../../../types/water-supply';
import { getWaterSupplyStatistics } from '../../../services/hcs';
import { AppDataContext } from '../../../contexts/AppDataContext';
import { useParams } from 'react-router-dom';
import { getDistrictKatoById } from '../../../utils';


const WaterStatisticCard = () => {
    const { selectedYear } = useContext(AppDataContext);
    const [statistic, setStatistic] = useState<IWaterSupplyStatistic>();
    const { id } = useParams(); 

    const loadStatistics = async () => {
        const response = await getWaterSupplyStatistics(id ? getDistrictKatoById(Number(id)) as string : "", selectedYear.value);

        if (!response.isError) {
            setStatistic(response.data);
        }
    };

    useEffect(() => {
        loadStatistics();
    }, [id, selectedYear]);

    return (
        <div className="side-info-big-card">
            <div className="side-info-big-card-title" style={{marginBottom: "12px"}}>Статистика водоснабжения</div>
            
            <div className="side-info-big-card-items" style={{gap: "8px"}}>
                <div className="side-info-big-card-item">
                    <div className="side-info-water-statistic-dots-wrapper">
                        <div className="side-info-water-statistic-dots">
                            <div className="dot" />
                            <div className="dot" style={{background: "#E0C225", left: "4px"}} />
                            <div className="dot" style={{background: "#B74747", left: "initial", right: 0}} />
                        </div>
                        Износ сетей
                    </div>
                    <div>{statistic?.networkWearPercentage}%</div>
                </div>
                <div className="side-info-big-card-item">
                    <div>КМ сетей</div>
                    <div>{statistic?.totalLength}</div>
                </div>
                <div className="side-info-big-card-item">
                    <div>Обслуживающие организации</div>
                    <div>{statistic?.serviceCompaniesCount}</div>
                </div>
                <div className="side-info-big-card-item">
                    <div>Количество абонентов</div>
                    <div>{statistic?.subscribersCount}</div>
                </div>
                <div className="side-info-big-card-item">
                    <div>Уровень тарифов</div>
                    <div>{statistic?.totalTariffLevels}</div>
                </div>
                <ul>
                    <li>
                        <div className="data-list-item">
                            <div>усредненный, тенге/м3</div>
                            <div>{statistic?.averageTariffLevels}</div>
                        </div>
                    </li>
                    <li>
                        <div className="data-list-item">
                            <div>физическим лицам/населению, тенге/м3</div>
                            <div>{statistic?.tariffForIndividuals}</div>
                        </div>
                    </li>
                    <li>
                        <div className="data-list-item">
                            <div>юридическим лицам (в том числе бюджетные организации), тенге/м3</div>
                            <div>{statistic?.tariffForLegalEntities}</div>
                        </div>
                    </li>
                    <li>
                        <div className="data-list-item">
                            <div>бюджетным организациям, тенге/м3</div>
                            <div>{statistic?.tariffForBudgetOrganizations}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default WaterStatisticCard;