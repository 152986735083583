import React, {useContext, useEffect, useRef, useState} from 'react';
import {getTendersList} from "../../../services/tenders";
import dayjs from "dayjs";
import {AppDataContext} from "../../../contexts/AppDataContext";
import {ITendersListItem} from "../../../types/tenders";
import {getOptionNameById, getSumString,} from "../../../utils";
import { budgetTypes, featureTypes, tradeMethodFact } from "../../../utils/options";
import {Tooltip} from "react-tooltip";
import {TendersContext} from "../../../contexts/TendersContext";

const TendersPointModal: React.FC<{id?: number, county?: number}> = ({id, county}) => {
    const {date} = useContext(AppDataContext);
    const {
        customer,
        supplier,
        subject,
        status,
        budgetType,
        type,
        tradeMethod,
        organizator,
        feature
    } = useContext(TendersContext);
    const tableHeader = ["Наименование\nобъявления", "Способ закупки", "Дата публикации\nобъявления", "Дата подведения итогов", "Плановая сумма объявления", "Сумма договоров", "Наименование заказчика","Признаки", "Имя поставщика", "Тип бюджета"];
    const [tenderList, setTenderList] = useState<ITendersListItem[]>([]);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const perPage = 50;
    const observerRef = useRef<IntersectionObserver | null>(null);
    const loaderRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (hasMore && !isFetching) {
            fetchTendersList();
        }
    }, [page]);

    useEffect(() => {
        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && !isFetching && hasMore) {
                setPage((prevPage) => prevPage + 1);
            }
        });

        if (loaderRef.current) observerRef.current.observe(loaderRef.current);

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
    }, [isFetching, hasMore]);

    const fetchTendersList = async () => {
        setIsFetching(true);
        const response = await getTendersList(
            dayjs(date).format("YYYY-MM-DD"), page, perPage, id, county, status.selectedIds, budgetType.selectedIds, customer.selectedIds, subject.ids, supplier.selectedIds, tradeMethod.selectedIds, type.ids, organizator.selectedIds, feature.ids
        );

        if (!response.isError) {
            setTenderList((prevTenderList) => {
                const newTenders = response.data.results.filter(newItem =>
                    !prevTenderList.some(existingItem => existingItem.id === newItem.id)
                );
                return [...prevTenderList, ...newTenders];
            });
            if (response.data.results.length < perPage) {
                setHasMore(false);
            }
        } else {
            setTenderList([]);
            setHasMore(false);
        }
        setIsFetching(false);
    };

    return (
        <div className="tenders-items">
            <div className="table-border tenders-table">
                <table className="table">
                    <thead className="table-header">
                        {tableHeader.map((item, index) =>
                            <td key={index} className='cell'>
                                {item}
                            </td>
                        )}
                    </thead>

                    <tbody>
                        {tenderList.map((item) =>
                            <tr className="row">
                                <td className="cell"><a href={item.announcement_link} className="tenders-items-link" target="_blank" rel="noopener noreferrer">{item.name}</a></td>
                                <td className="cell">{getOptionNameById(tradeMethodFact, item.trade_method_fact)}</td>
                                <td className="cell"><div>{dayjs(item.announcement_publish_date).format("DD.MM.YYYY hh:mm")}</div>

                                    <Tooltip id="my-tooltip" place="top-start" style={{zIndex: 999}}/>
                                </td>
                                <td className="cell">
                                    <div>{dayjs(item.itogi_public_date).format("DD.MM.YYYY hh:mm")}</div>

                                </td>
                                <td className="cell">{getSumString(item.announcement_total_sum)+ " ₸"}</td>
                                <td className="cell">{getSumString(item.total_sum)+ " ₸"}</td>
                                <td className="cell">{item.customer.name}</td>
                                <td className="cell">{getOptionNameById(featureTypes, item.feature)}</td>
                                <td className="cell">{item.supplier.map(sup => sup.name)}</td>
                                <td className="cell">{getOptionNameById(budgetTypes, item.budget_type)}</td>
                            </tr>
                        )}
                        <div ref={loaderRef}/>
                        {isFetching &&
                            <tr className="row">
                                <td colSpan={tableHeader.length} className="table-loading">Загрузка данных...</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TendersPointModal;