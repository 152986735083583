import React, {useContext, useEffect, useState} from 'react';
import {ChevronOutline} from "../Icons";
import BarChart from "../Charts/BarChart";
import {chartColors} from "../../utils/options";
import { AppDataContext } from '../../contexts/AppDataContext';
import { ITenderContract, ITenderContractEconomy } from '../../types/tenders';
import dayjs from 'dayjs';
import { getTendersContractsChart, getTendersContractsEconomyChart } from '../../services/tenders';
import { getSumString } from '../../utils';

const TendersContractsCard = () => {
    const [page, setPage] = useState<number>(0);
    const { date } = useContext(AppDataContext);
    const [chartData, setChartData] = useState<ITenderContract>();
    const [chartDataEconomy, setChartDataEconomy] = useState<ITenderContractEconomy>();
    const titles = ["Договоры гос. закупок", "Договоры гос. закупок"];

    const loadContractsChart = async () => {
        const response = await getTendersContractsChart(dayjs(date).format("YYYY-MM-DD"));
        
        if (!response.isError) {
            setChartData(response.data);
        }
    }

    const loadContractsChartEconomy = async () => {
        const response = await getTendersContractsEconomyChart(dayjs(date).format("YYYY-MM-DD"));
        
        if (!response.isError) {
            setChartDataEconomy(response.data);
        }
    }

    useEffect(() => {
        loadContractsChart();
        loadContractsChartEconomy();
    }, [date]);

    const getChartData = () => {
        let names: string[] = ["Сумма договора", "Увеличение суммы", "Экономия по договору"];
        let statusesData: number[] = [Math.round(chartDataEconomy?.sum_contracts as number), chartDataEconomy?.sum_increase as number, chartDataEconomy?.sum_economy as number];

        return {
            labels: names,
            datasets: [
                {
                    data: statusesData,
                    backgroundColor: chartColors,
                    borderColor: chartColors,
                    borderWidth: 1,
                    //barThickness: 18, -фикс ширина баров
                }
            ]
        }
    };

    const getSmallChartData = () => {
        let names: string[] = chartData?.status.map((object) => object.label) as string[];
        let statusesData: number[] =  chartData?.status.map((object) => {
            if (object.amount) {
                return Math.round(object.amount)
            } else {
                return 0;
            }
        }) as number[];

        return {
            labels: names,
            datasets: [
                {
                    data: statusesData,
                    backgroundColor: chartColors,
                    borderColor: chartColors,
                    borderWidth: 1,
                    //barThickness: 18, - фикс ширина баров
                }
            ]
        }
    };

    const sum: number = chartDataEconomy ? chartDataEconomy.sum_contracts + chartDataEconomy.sum_increase + chartDataEconomy?.sum_economy : 0;

    return (
        <div className="side-info-big-card">
            <div className="side-info-big-card-slider">
                <span className="side-info-big-card-slider-arrow prev" onClick={() => setPage(page === 0 ? 1 : 0)}><ChevronOutline /></span>
                <span className="side-info-big-card-title">{titles[page]}</span>
                <span className="side-info-big-card-slider-arrow" onClick={() => setPage(page === 0 ? 1 : 0)}><ChevronOutline /></span>
            </div>

            {page === 0 &&
                <div>
                    <BarChart
                        data={getSmallChartData()}
                        isLegend={false}
                        responsive
                        horizontal
                        chartHeight={200}
                        datalabels
                        textRotation={180}
                        datalabelsFormatter={(value) => getSumString(value) + " ₸"}
                    />
                </div>
            }

            {page === 1 &&
                <>
                    <p className="side-info-big-card-subtitle">Общее количество {getSumString(sum)}</p>
                    <div>
                        <BarChart
                            data={getChartData()}
                            isLegend={false}
                            responsive
                            horizontal
                            chartHeight={100}
                            datalabels
                            textRotation={180}
                            datalabelsFormatter={(value) => getSumString(value)  + " ₸"}
                        />
                    </div>
                </>
            }
        </div>
    );
};

export default TendersContractsCard;