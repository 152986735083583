import { useContext, useEffect, useState } from "react";
import Search from "../../UI/Search";
import DonutChart from "../../Charts/DonutChart";
import BarChart from "../../Charts/BarChart";
import Modal from "../../UI/Modal";
import DebtsGeneralModal from "./modals/DebtsGeneralModal";
import {
    getDebtsBarChartData,
    getDebtsDonutChartData,
    getDebtsFile,
    getDebtsTopFive,
    getGeneralDebts
} from "../../../services/hcs";
import { BarChartData, DebtsDistrict, DebtsRecord, DebtsTopfive, DonutChartData, GeneralDebt } from "../../../types/debts";
import {AppDataContext} from "../../../contexts/AppDataContext";
import dayjs from "dayjs";
import { Tooltip } from "react-tooltip";
import { Arrow } from "../../Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { DebtsContext } from "../../../contexts/DebtsContext";
import {chartColors} from "../../../utils/options";
import { getSumString } from "../../../utils";

const DebtsSideInfo: React.FC<{district?: DebtsDistrict}> = ({district}) => {
    const { text, setText, krp, kse, type } = useContext(DebtsContext);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { dateRange } = useContext(AppDataContext);
    const [barChartData, setBarChartData] = useState<BarChartData>();
    const [donutChartData, setDonutChartData] = useState<DonutChartData>();
    const [generalDebt, setGeneralDebt] = useState<GeneralDebt>();
    const [topfive, setTopfive] = useState<DebtsTopfive[]>();
    const navigate = useNavigate();
    const location = useLocation();

    const loadGeneralDebts = async (): Promise<void> => {
        const response = await getGeneralDebts(dayjs(dateRange.from).format("YYYY-MM-DD"), dayjs(dateRange.to).format("YYYY-MM-DD"), "");

        if (!response.isError) {
            setGeneralDebt(response.data);
        }
    };

    const loadBarChartData = async () => {
        const response = await getDebtsBarChartData(dayjs(dateRange.from).format("YYYY-MM-DD"), dayjs(dateRange.to).format("YYYY-MM-DD"), district ? `${district.id}` : "", krp.selected, kse.selected, type.selected);

        if (!response.isError) {
            setBarChartData(response.data);
        }
    };

    const loadDonutChartData = async () => {
        const response = await getDebtsDonutChartData(dayjs(dateRange.from).format("YYYY-MM-DD"), dayjs(dateRange.to).format("YYYY-MM-DD"), district ? `${district.id}` : "", krp.selected, kse.selected, type.selected);

        if (!response.isError) {
            setDonutChartData(response.data);
        }
    };

    const loadTopFive = async () => {
        const response = await getDebtsTopFive(dayjs(dateRange.from).format("YYYY-MM-DD"), dayjs(dateRange.to).format("YYYY-MM-DD"), district?.id as number);

        if (!response.isError) {
            setTopfive(response.data);
        }
    };

    useEffect(() => {
        loadGeneralDebts();
        loadBarChartData();
        loadDonutChartData();
        if (district) {
            loadTopFive();
        }
    }, [dateRange]);

    useEffect(() => {
        loadBarChartData();
        loadDonutChartData();
        
        if (district) {
            loadTopFive();
        }
    }, [district]);

    useEffect(() => {
        loadBarChartData();
        loadDonutChartData();
    }, [krp.selected, kse.selected, type.selected]);

    const removeZeros = (): {labels: string[], data: number[]} => {
        const labels: string[] = [];
        const data: number[] = [];

        if (barChartData) {
            for (let i = 0; i < barChartData.kse_data.length; i++) {
                if (parseInt(barChartData.kse_data[i].total_outstanding_amount) !== 0) {
                    labels.push(barChartData.kse_data[i].kse_name);
                    data.push(parseInt(barChartData.kse_data[i].total_outstanding_amount));
                }
            }
        }
        return {labels, data};
    };

    const barChartInfo = {
        labels: removeZeros().labels,
        datasets: [
            {
                data: removeZeros().data,
                backgroundColor: chartColors,
                borderColor: chartColors,
                borderWidth: 1
            }
        ]
    };

    const downloadFile = async () => {
        const response = await getDebtsFile(dayjs(dateRange.from).format("YYYY-MM-DD"), dayjs(dateRange.to).format("YYYY-MM-DD"));
        
        if (!response.isError) {
            const fileUrl = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute("download", `Отчёт по задолженностям(${dayjs(dateRange.from).format("YYYY-MM-DD")} - ${dayjs(dateRange.to).format("YYYY-MM-DD")}).xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } else {
            console.error('Произошла ошибка при скачивании файла:', response.error);
        }
    };

    const filteredHints = generalDebt?.records.filter((debt: DebtsRecord) => (debt.organization_name.toLowerCase().includes(text.toLowerCase()) || debt.bin_code.includes(text)));
    const topFiveSum = topfive?.reduce((sum: number, object: DebtsTopfive) => sum + parseFloat(object.latest_outstanding_amount), 0).toString().substring(0, 11);

    const topFiveColors = ["#E63349", "#494949", "#FFB933", "#B26376", "#27C8BE"];

    return (
        <>
            <div className="side-info side-info-scroll">

                {district &&
                <div className="debts-back">
                    <span className="debts-back-button" onClick={() => navigate("/debts")}><Arrow /></span>
                    <div className="debts-back-city">
                        {district.name}
                    </div>
                </div>
                }

                <Search
                    value={text}
                    placeholder="Наименование организации или БИН" 
                    onChange={(e) => setText(e)} 
                    onCancel={() => setText("")}
                />

                {(text.length !== 0 && text.length < 10) ?
                    <div className="input-hints">
                        {filteredHints!.length === 0 ? <div style={{width: "fit-content", height: "fit-content"}}>Совпадения не найдены</div> :
                            (filteredHints!.map((object: DebtsRecord, i: number) =>
                                <span 
                                    key={i} 
                                    className="input-hints-item" 
                                    onClick={() => {
                                        setText(object.organization_name);
                                        setOpenModal(true);
                                    }}
                                    data-tooltip-id="hint"
                                    data-tooltip-content={object.organization_name}
                                    data-tooltip-offset={40}
                                >
                                    {`${object.bin_code}, ${object.organization_name}`}
                                </span>
                            ))
                        }
                        
                    </div>
                    : null 
                }

                {donutChartData ? 
                    <div className="debts-donut" style={{justifyContent: "space-between", gap: "24px", position: "relative"}}>
                        <div style={{width: "100%"}}>
                            <div style={{display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "500"}}>
                                <span>ВСЕГО ОРГАНИЗАЦИЙ</span>
                                <span>{donutChartData.total_companies}</span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", fontSize: "12px"}}>
                                <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                                    <div className="small"/>
                                    <span>Малые</span>
                                </div>
                                <span>{donutChartData?.small_companies as number}</span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", fontSize: "12px"}}>
                                <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                                    <div className="middle"/>
                                    <span>Средние</span>
                                </div>
                                <span>{donutChartData?.medium_companies as number}</span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", fontSize: "12px"}}>
                                <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                                    <div className="big"/>
                                    <span>Большие</span>
                                </div>
                                <span>{donutChartData?.large_companies as number}</span>
                            </div>
                        </div>

                        <div className="debts-donut-graph">
                            <DonutChart
                                labels={["Малые", "Средние", "Большие"]} 
                                values={[donutChartData?.small_companies as number, donutChartData?.medium_companies as number, donutChartData?.large_companies as number]}
                                backgroundColor={["#00D67E", "#FFB933", "#3880CE"]}
                                thickness="85%"
                                borderRadius={50}
                                distanceBetweenSegments={7}
                                rotation={150}
                                customTooltip={{top: "210", left: "325"}}
                            />
                        </div>

                        {(donutChartData.small_companies === 0 && donutChartData.medium_companies === 0 && donutChartData.large_companies === 0) ? 
                            null : 
                            <span style={{position: "absolute", display: "flex", fontSize: "24px", top: "29.5%", right: "9.4%"}}>%</span>
                        }
                    </div>
                    :   <div className="debts-donut" style={{justifyContent: "center"}}>Нет данных за выбранный период</div>
                }

                <div className="debts-donut" style={{display:"flex", flexDirection: "column", height: "fit-content", gap: "12px"}}>
                    <span style={{display: "flex", fontSize: "16px", fontWeight: "700", width: "100%"}}>Классификатор секторов экономики:</span>
                    {barChartData ? 
                        <>
                            <span style={{display: "flex", fontSize: "12px", width: "100%"}}>Общая задолжность: {Math.floor(Number(barChartData.total_outstanding_amount)).toLocaleString("ru-RU")} ₸</span>

                            <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                                <BarChart 
                                    data={barChartInfo}
                                    isLegend={false}
                                    responsive
                                    chartHeight={100}
                                    onlyGraph
                                    breakWords
                                />
                            </div>

                            {barChartInfo &&
                                <div style={{display: 'flex', flexDirection: 'column', gap: '8px', width: "100%", overflow: 'hidden'}}>
                                    {removeZeros().labels.map((item, index) =>
                                        <div style={{display: "flex", gap: "4px", width: "100%", alignItems: 'center'}} key={index}>

                                            <div style={{display: "inline-block", flexShrink: 0, width: "16px", height: "16px", borderRadius: "2px", backgroundColor: chartColors[index]}} />

                                            <p style={{ fontSize: "12px" }} title={item}>
                                                {item}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            }
                        </>
                        : <div style={{textAlign: "center"}}>Нет данных за выбранный период</div>
                    }
                </div>

                {location.pathname === "/debts" && 
                    <div className="open-modal-card" onClick={() => setOpenModal(true)}>Просмотр общей задолжности</div>
                }

                {district && 
                    <div className="debts-topfive">
                        <span className="debts-topfive-title">ТОП {topfive?.length} организаций по задолжностям:</span>

                        <div style={{position: 'relative'}}>
                            <div className="debts-donut-graph" style={{width: "135px", height: "135px"}}>
                                <DonutChart
                                    labels={topfive?.map((object: DebtsTopfive) => object.full_name) as string[]} 
                                    values={topfive?.map((object: DebtsTopfive) => parseFloat(object.latest_outstanding_amount)) as number[]}
                                    backgroundColor={topFiveColors}
                                    thickness="85%"
                                    borderRadius={50}
                                    distanceBetweenSegments={7}
                                    rotation={150}
                                    customTooltip={{top: "510", left: "250"}}
                                    withPercentageInTooltip
                                />
                            </div>

                            <span style={{position: "absolute", display: "flex", fontSize: "12px", top: "50%", left: "50%", fontWeight: "600", width: "87px", transform: 'translateY(-50%) translateX(-50%)', textAlign: "center" }}>
                                Общая задолжность: {topFiveSum ? Math.floor(Number(topFiveSum)).toLocaleString() : ""} ₸
                            </span>
                        </div>

                        {topfive &&
                            <div style={{display: 'flex', flexDirection: 'column', gap: '8px', width: "100%", overflow: 'hidden'}}>
                                {
                                    topfive.map((debt, index) => {
                                        return (
                                            <div style={{display: "flex", gap: "4px", width: "100%", alignItems: 'center'}} key={debt.bin_code}>

                                                <div style={{display: "inline-block", flexShrink: 0, width: "16px", height: "16px", borderRadius: "2px", backgroundColor: topFiveColors[index]}} />

                                                <p style={{ fontSize: "12px" }} title={debt.full_name}>
                                                    {debt.full_name}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                }

                    {/* <div style={{display: "flex", height: "100%", alignItems: "flex-end"}}>
                        <div className="debts-legend">
                            <div className="debts-legend-wrapper">
                                <span className="debts-legend-yellow"></span>
                                <span className="debts-legend-description">- Выбранный район</span>
                            </div>
                            <div className="debts-legend-wrapper">
                                <span className="debts-legend-red"></span>
                                <span className="debts-legend-description">- Больше 20% юр. лиц имеют долги</span>
                            </div>
                        </div>
                    </div> */}
            </div>

            <Modal 
                title={`Дебиторская задолжность ШЭТ: ВСЕГО ${getSumString(Math.floor(Number(barChartData?.total_outstanding_amount)))} ₸`}
                isOpen={openModal} 
                buttonText="Экспорт"
                buttonAction={downloadFile}
                onClose={() => setOpenModal(false)}
            >
               <DebtsGeneralModal text={text}/>
            </Modal>

            <Tooltip id="hint" place="right" style={{textWrap: "wrap", wordWrap: "break-word", maxWidth: "250px", fontSize: "12px", zIndex: "555"}}/>
        </>
    )
}

export default DebtsSideInfo;