import React, { useEffect, useMemo, useState } from "react";
import { MenuIcon } from "../Icons";
import ReactDOM from "react-dom";
import AsideMenuItem, { AsideMenuItemProps } from "./AsideMenuItem";
import { IconLeft } from "react-day-picker";

const ASIDE_ITEMS_PRODUCTION: AsideMenuItemProps[] = [
    {
        name: "Главное",
        href: "https://situation-centre.kz/",
    },
    {
        name: "Модуль GIS",
        href: "https://maps.situation-centre.kz/",
        disabled: true,
    },
    {
        name: "Медиапланирование",
        href: "https://media.situation-centre.kz/",
    },
];

const ASIDE_ITEMS_DEVELOPMENT: AsideMenuItemProps[] = [
    {
        name: "Главное",
        href: "https://sit.robosoft.kz/",
    },
    {
        name: "Модуль GIS",
        href: "https://maps.robosoft.kz/",
        disabled: true,
    },
    {
        name: "Медиапланирование",
        href: "https://media.robosoft.kz/",
    },
    {
        name: "Аналитические дайджесты",
        href: "https://sit.robosoft.kz/digests/",
    },
];

const ASIDE_ITEMS_BY_ENV = {
    development: ASIDE_ITEMS_DEVELOPMENT,
    production: ASIDE_ITEMS_PRODUCTION,
};

const asideItems =
    ASIDE_ITEMS_BY_ENV[
        process.env.REACT_APP_ENV as keyof typeof ASIDE_ITEMS_BY_ENV
    ] || ASIDE_ITEMS_PRODUCTION;

const HeaderAsideMenu: React.FC = () => {
    const [showMenu, setShowMenu] = useState(false);

    const handleToogleMenu = () => setShowMenu((prev) => !prev);

    const computedClasses = useMemo(() => {
        return ["header-aside-menu", !showMenu && "--aside-closed"]
            .filter(Boolean)
            .join(" ");
    }, [showMenu]);

    useEffect(() => {
        const eventHandler = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setShowMenu(false);
            }
        };

        document.addEventListener("keydown", eventHandler);

        return () => document.removeEventListener("keydown", eventHandler);
    }, []);

    return (
        <>
            <button
                className="header-menu-btn"
                type="button"
                onClick={handleToogleMenu}
            >
                <MenuIcon />
            </button>

            {ReactDOM.createPortal(
                <aside className={computedClasses}>
                    <div className="header-aside-menu-inner">
                        {asideItems.map((item, index) => {
                            return <AsideMenuItem {...item} key={index} />;
                        })}

                        <button
                            type="button"
                            className="header-aside-menu-link"
                            style={{
                                marginTop: "auto",
                            }}
                            onClick={handleToogleMenu}
                        >
                            <IconLeft />
                            Закрыть
                        </button>
                    </div>

                    <div
                        className="header-aside-menu-backdrop"
                        onClick={handleToogleMenu}
                    />
                </aside>,
                document.getElementById("aside-menu")!
            )}
        </>
    );
};

export default HeaderAsideMenu;
