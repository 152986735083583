import React, {useContext, useState} from 'react';
import Dropdown from "./Dropdown";
import { AppDataContext } from '../../contexts/AppDataContext';

const YearPicker = () => {
    const { selectedYear, updateSelectedYear } = useContext(AppDataContext);

    const years =
        Array.from(new Array(6), (_, index) => {
            const year = new Date().getFullYear() - 5 + index;
            return { value: String(year), label: String(year) }
        });

    return (
        <div>
            <Dropdown
                options={years}
                onSelect={updateSelectedYear}
                selectedOpt={selectedYear}
            />
        </div>
    );
};

export default YearPicker;