import { divIcon, DivIconOptions, LatLngExpression } from "leaflet";
import { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { IDistrictInfo } from "../../../types/general";
import { Marker } from "react-leaflet";
import Modal from "../../UI/Modal";
import { WaterSupplyIcon } from "../../Icons";

const WaterSupplyMarker:React.FC<{point: IDistrictInfo}> = ({point}) => {
    const markerRef = useRef<any>(null);
    const [isModal, setIsModal] = useState<boolean>(false);
    const [chosenLocality, setChosenLocality] = useState<IDistrictInfo | null>(null);

    const createFireMarker = () => {
        const options: DivIconOptions = {
            className: "marker-wrapper",
            html: ReactDOMServer.renderToString(
                <div className="info-marker">
                    <WaterSupplyIcon />
                </div>
            )}
        return divIcon(options);
    };

    useEffect(() => {
        const marker = markerRef.current;
        if (marker) {
            marker.on('mouseover', function () {
                marker.openPopup();
            });
            marker.on('mouseout', function () {
                marker.closePopup();
            });
        }
    }, []);

    const showModal = () => {
        setIsModal(true);
        setChosenLocality(point);
    };

    return (
        <>
            <Marker
                position={[point.latitude, point.longitude] as LatLngExpression}
                icon={createFireMarker()}
                ref={markerRef}
                eventHandlers={{
                    click: showModal,
                }}
            />

            {chosenLocality &&
                <Modal
                    isOpen={isModal}
                    onClose={() => {
                        setIsModal(false);
                        setChosenLocality(null);
                    }}
                    title={chosenLocality.name}
                    bigModal
                >
                    <>#</>
                </Modal>
            }
        </>
    )
}

export default WaterSupplyMarker; 