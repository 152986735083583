import {IDistrict} from "../types/district";
import axiosInstance from "./axiosInstance";

type TResult<DataType> =
    | {
    isError: true;
    error: string;
}
    | {
    isError: false;
    data: DataType;
};

export const getDistricts = async (date: string): Promise<TResult<IDistrict[]>> => {
    try {
        const response = await axiosInstance.get('/api/core/districts/', {
            params: {
                date: date,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getDistrictById = async (id: number, date: string): Promise<TResult<IDistrict>> => {
    try {
        const response = await axiosInstance.get(`/api/core/districts/${id}/`, {
            params: {
                date: date,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getCheckpointStatistic = async (id: number, month: string, end_date?: string): Promise<TResult<any>> => {
    try {
        const response = await axiosInstance.get('/api/hydrometeorology/hydrometeorological-average-details/', {
            params: {
                checkpoint_id: id,
                month_quantity: month,
                end_date,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getHydroNodes = async (): Promise<TResult<any>> => {
    try {
        const response = await axiosInstance.get('/api/hydronodes/constructions/');

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getFloodProneAreas = async (): Promise<TResult<any>> => {
    try {
        const response = await axiosInstance.get('/api/flood_prone_settlements/list/');

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getFloodsFile = async (districtId?: string): Promise<TResult<Blob>> => {
    try {
        const response = await axiosInstance.get('/api/hydrometeorology/excel/', {
            params: {
                district: districtId,
            },
            responseType: 'blob'
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};